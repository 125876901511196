

















































import {Component, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import ContactPerson from '@/models/ContactPerson';
import {KeyOf} from '@/misc/Parseable';

const CustomerStore = namespace('customer');

@Component({})
export default class LocationMasterDataComponent extends Vue {
  @CustomerStore.Getter('customer')
  public _customer!: Customer;
  @CustomerStore.Getter('location')
  public _location!: Location;

  private rendered: boolean = false;

  public mounted() {
    this.rendered = true;
  }

  get localLocation() {
    return this._location;
  }

  get localCustomer() {
    return this._customer;
  }


  get contactPerson(): ContactPerson[] {
    return this._location?.contactPersons ?? this._customer?.contactPersons!;
  }

  private get masterData(): Array<{
    title: string, item: Customer | Location, shownData:
      { attribute: KeyOf<Location & Customer>, default?: string, nested: string[] },
  }> {
    return [
      {
        title: this.$t('GENERAL.ADDRESS').toString(),
        item: this.localLocation,
        shownData: {
          attribute: 'address',
          nested: ['street', 'house_No', 'postal_Code', 'city'],
        },
      },
      {
        title: this.$t('GENERAL.INVOICE_ADDRESS').toString(),
        item: this.localCustomer,
        shownData: {
          attribute: 'invoiceAddress',
          default: 'address',
          nested: ['street', 'house_No', 'postal_Code', 'city'],
        },
      },
      {
        title: this.$t('LOCATION_DASHBOARD.OWN_CONTACT_PERSONS').toString(),
        item: this.localLocation,
        shownData: {
          attribute: 'contactPersons',
          nested: ['first_Name', 'last_Name', 'phone', 'email'],
        },
      },
      {
        title: this.$t('LOCATION_DASHBOARD.CUSTOMERS_CONTACT_PERSONS').toString(),
        item: this.localCustomer,
        shownData: {
          attribute: 'contactPersons',
          nested: ['first_Name', 'last_Name', 'phone', 'email'],
        },
      },
    ];
  }

  private returnData(item: any, attrs: string[], key: string) {
    for (const attribute of attrs) {
      if (!attribute) {
        continue;
      }
      const data: any = item?.[attribute];
      let retVal: any;
      if (data !== undefined && data !== null) {
        retVal = data[key.replace('_', '')];
        switch (typeof retVal) {
          case 'string':
            return retVal ? retVal : '-';
          case 'number':
          case 'bigint':
            return retVal;
          case 'boolean':
            return retVal ? this.$t('GENERAL.YES') : this.$t('GENERAL.NO');
          default:
            console.log('something I did not recognize: ', retVal);
            return '-';
        }
      }
    }
    return '-';
  }
}
